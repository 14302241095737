

h2 {
  @apply text-3xl md:text-3xl font-black font-primary text-neutral-950 m-0;
}

h3 {
  @apply text-2xl md:text-2xl font-black font-primary text-neutral-950 m-0;
}

.h1 {
  @apply text-6xl normal-case font-black;
}

.h1:before {
  display: none;
}

.LeagueTableDashboardGrid__tableHeaderType {
  @apply text-black;
}

.LeagueTableDashboardGrid__table {
  @apply rounded-xl overflow-hidden
}

.LeagueTableDashboardGrid__tableName .TeamAvatar {
  @apply rounded-none rounded-b-full border border-neutral-200;
}

.TeamTable__update {
  @apply mb-24;
}
