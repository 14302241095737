

.Header {
  @apply mt-40;

  &__crosses {
    @apply hidden;
  }

  &__title {
    @apply before:hidden m-0 font-black text-4xl sm:text-6xl font-primary text-black block text-left
  }
}

.Menu {
  @apply top-20;
}

.MatchJumbotron {
  @apply top-[64px] sm:top-[80px];
}
