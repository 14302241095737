

.MatchHeaderAlt__layout {
  @apply mt-40 after:hidden;
}

.MatchHeaderAlt__navigation h1 {
  @apply before:hidden m-0 font-black text-3xl sm:text-6xl font-primary text-black block text-center sm:text-left;
}

.MatchHeaderAlt__teamCrosses {
  @apply hidden;
}
