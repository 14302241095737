

.Content {

  p {
    @apply text-base sm:text-lg;
  }

  h2 {
    @apply text-2xl sm:text-4xl font-black font-primary text-neutral-950 normal-case
  }

  h3 {
    @apply text-2xl font-black font-primary text-neutral-950 normal-case
  }

  p {
    @apply text-neutral-950;

    a {
      @apply text-wmf-sky;
    }
  }

  &__link {
    @apply bg-white shadow mx-0 rounded;

    &Image {
      @apply min-w-[146px] rounded overflow-hidden;
    }

    &ContentTitle {
      @apply text-xl font-black font-primary text-neutral-950 normal-case
    }

    &ContentDescription {
      @apply text-base text-neutral-950;
    }
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc list-image-none;
  }

  hr {
    @apply bg-neutral-200 h-0.5 w-full;
  }

  &__alert {
    @apply bg-wmf-red;

    &Title {
      @apply text-xl font-black font-primary normal-case;
    }

    &Message {
      @apply text-base;
    }
  }

  table {
    @apply border-none;

    tr td {
      @apply bg-neutral-100 border-none text-neutral-950;
    }

    tr:nth-child(2n+1) td {
      @apply bg-neutral-200;
    }
  }

  &__attachment {
    @apply bg-white shadow;

    &Title {
      @apply text-xl font-black font-primary normal-case text-neutral-950;
    }

    &Size {
      @apply font-bold;
    }

    &Icon {
      @apply text-wmf-green;
    }
  }

  table a {
    @apply text-wmf-sky underline;
  }
}
